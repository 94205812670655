import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const FullImage = ({ title, image }) => {
  const classes = useStyles({ image });

  return (
    <div className={classes.background}>
      <Grid align="center">
        <Grid item xs={10}>
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles({
  title: {
    fontWeight: 600,
  },
  background: ({ image }) => ({
    color: 'white',
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    paddingTop: '9em',
    paddingBottom: '9em',
    maxWidth: '100%',
    position: 'relative',
    zIndex: 1,
    '&::after': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.6)',
      zIndex: -1,
    },
  }),
});

FullImage.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
};

FullImage.defaultProps = {
  title: '',
  image: '',
};

export default FullImage;
