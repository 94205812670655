import React, { useState } from 'react';
import {
  AppBar, Toolbar, Container, Typography, List, ListItem,
  IconButton, SwipeableDrawer, ListItemText, Divider, Hidden,
  Button,
} from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  MoreVert as MoreVertIcon,
  EmailOutlined as EmailIcon,
  FolderSpecialOutlined as FolderSpecialIcon,
  AssessmentOutlined as AssessmentIcon,
} from '@material-ui/icons';

import images from '../assets/images';
import i18n from '../i18n';

const SHRINK_ON = 150;

const Header = () => {
  const { components: { header: texts } } = i18n;
  const classes = useStyles();

  const [resizeToolbar, setResizeToolbar] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const { pathname } = useLocation();
  const initPathname = `/${pathname.split('/')[1]}` || null;

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenMenu(open);
  };

  const resizeHeaderOnScroll = () => {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop;
    if (distanceY >= SHRINK_ON) {
      setResizeToolbar(true);
    } else {
      setResizeToolbar(false);
    }
  };
  window.addEventListener('scroll', resizeHeaderOnScroll);

  const MobileMenu = () => (
    <>
      <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={toggleDrawer(true)}>
        <MoreVertIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={openMenu}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div role="presentation" onKeyDown={toggleDrawer(false)}>
          <List>
            <ListItem component={Link} to="/proyectos" onClick={toggleDrawer(false)}>
              <ListItemText primary="Proyectos" />
            </ListItem>
            <ListItem component={Link} to="/balances" onClick={toggleDrawer(false)}>
              <ListItemText primary="Balances" />
            </ListItem>
            <ListItem component={Link} to="/contacto" onClick={toggleDrawer(false)}>
              <ListItemText primary="Contáctanos" />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button component={Link} to="/">
              <ListItemText primary={texts.title} />
            </ListItem>
          </List>
        </div>
      </SwipeableDrawer>
    </>
  );

  const DesktopMenu = () => (
    <>
      <Button color="inherit" component={Link} to="/proyectos">
        <FolderSpecialIcon className={classes.iconDesktop} />
        <span>
          Proyectos
          {initPathname === '/proyectos' && (<span className={classes.lineBottom} />)}
        </span>
      </Button>
      <Button color="inherit" component={Link} to="/balances">
        <AssessmentIcon className={classes.iconDesktop} />
        <span>
          Balances
          {initPathname === '/balances' && (<span className={classes.lineBottom} />)}
        </span>
      </Button>
      <Button color="inherit" component={Link} to="/contacto">
        <EmailIcon className={classes.iconDesktop} />
        <span>
          Contáctanos
          {initPathname === '/contacto' && (<span className={classes.lineBottom} />)}
        </span>
      </Button>
    </>
  );

  return (
    <div className={classes.init}>
      <Container>
        <AppBar position="static" elevation={0}>
          <Toolbar className={resizeToolbar ? classes.toolbarSmaller : classes.toolbar} disableGutters>
            <Typography variant="h6" className={classes.title}>
              <Link to="/">
                {images.headerLogo && (
                <img
                  src={images.headerLogo}
                  alt={texts.title}
                  className={resizeToolbar ? classes.imageSmaller : classes.image}
                />
                )}
              </Link>
            </Typography>
            <Hidden smDown>
              <DesktopMenu />
            </Hidden>
            <Hidden mdUp>
              <MobileMenu />
            </Hidden>
          </Toolbar>
        </AppBar>
      </Container>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  init: {
    flexGrow: 0,
    position: 'sticky',
    top: 0,
    left: 'auto',
    right: 0,
    backgroundColor: '#F5F5F5',
    zIndex: 2,
  },
  lineBottom: {
    left: 'calc(50%)',
    width: '22px',
    bottom: '8px',
    height: '1px',
    position: 'absolute',
    transition: 'opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: '#822F56',
  },
  iconDesktop: {
    paddingRight: '.1em',
  },
  iconMobile: {
    paddingRight: '1.2em',
  },
  title: {
    flexGrow: '1',
    lineHeight: '1em',
  },
  toolbar: {
    minHeight: '6.5em',
    transition: 'min-height 0.25s ease-out',
  },
  image: {
    maxHeight: '2.8em',
    transition: 'max-height 0.25s ease-out',
    marginLeft: '1em',
  },
  toolbarSmaller: {
    minHeight: '4.5em',
    transition: 'min-height 0.25s ease-out',
  },
  imageSmaller: {
    maxHeight: '2.2em',
    transition: 'max-height 0.25s ease-out',
    marginLeft: '1em',
  },
}));

export default Header;
