import React, { useEffect, useState, useContext } from 'react';
import {
  Grid, Container, Button, Hidden, GridListTile, GridList, CircularProgress,
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ImageZoom from 'react-medium-image-zoom';

import i18n from '../../i18n';
import { Division, DownloadButton, YoutubeVisualizer } from '../../components';
import WebContext from '../../contexts/webContext';

const Proyectos = ({ match: { params: { id } } }) => {
  const { pages: { proyectos: texts } } = i18n;
  const [principal, setPrincipal] = useState({});
  const { projects } = useContext(WebContext);

  useEffect(() => {
    setPrincipal(projects.data.find((item) => item.id.toString() === id));
  }, [id, projects]);

  return (
    <Container>
      <Helmet>
        <meta name="description" content={texts.description} />
        <meta property="og:description" content={texts.description} />
      </Helmet>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Division title={texts.title} description={texts.content} isMain />
        </Grid>
      </Grid>

      {projects.initializing && (
      <Grid container spacing={3} alignItems="center" direction="column">
        <Grid item xs={12}>
          <CircularProgress color="secondary" />
        </Grid>
      </Grid>
      )}

      {principal && (
      <Grid container spacing={3} direction="column" alignItems="center">
        <Grid item xs={10} sm={8}>
          <Division title={principal.title} description={principal.content} descriptionAlign="justify" />
        </Grid>

        {principal.youtubeId && (
          <Grid
            item
            xs={10}
            sm={8}
            style={{
              position: 'relative', width: '100%', height: 0, paddingBottom: '50%', margin: '1em',
            }}
          >
            <YoutubeVisualizer youtubeId={principal.youtubeId} />
          </Grid>
        )}

        {principal.images && (
        <Grid item xs={10} sm={8}>
          <Hidden smDown>
            <GridList cellHeight={240} cols={3}>
              {principal.images.map((item) => (
                <GridListTile key={item.name} cols={item.cols || 1}>
                  <ImageZoom
                    image={{
                      src: item.url,
                      alt: item.name,
                      style: {
                        height: '100%',
                        left: ' 50%',
                        position: 'relative',
                        transform: 'translateX(-50%)',
                      },
                    }}
                    className="MuiGridListTile-imgFullWidth"
                    zoomImage={{ src: item.url, alt: item.name }}
                  />
                </GridListTile>
              ))}
            </GridList>
          </Hidden>
          <Hidden mdUp>
            <GridList cellHeight={240} cols={1}>
              {principal.images.map((item) => (
                <GridListTile key={item.name} cols={item.cols || 1}>
                  <ImageZoom
                    image={{
                      src: item.url,
                      alt: item.name,
                      style: {
                        width: '100%',
                        top: '50%',
                        position: 'relative',
                        transform: 'translateY(-50%)',
                      },
                    }}
                    className="MuiGridListTile-imgFullWidth"
                    zoomImage={{ src: item.url, alt: item.name }}
                  />
                </GridListTile>
              ))}
            </GridList>
          </Hidden>
        </Grid>
        )}

        {principal.files && principal.files.map((file) => (
          <Grid item xs={12} sm={4} key={file.id}>
            <DownloadButton url={file.url} name={file.name} />
          </Grid>
        ))}
      </Grid>
      )}

      {projects.data.length > 0 && principal && (
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Division title={texts.subtitle} isMain />
          </Grid>
        </Grid>
      )}

      {projects.data.length > 0 && (
      <Grid container spacing={3}>
        {projects.data.map((item) => (
          <Grid item xs={12} sm={4} key={item.id}>
            <Division
              title={item.title}
              description={item.content}
              descriptionAlign="justify"
              maxChararters={180}
              path={`/proyectos/${item.id}`}
            />
          </Grid>
        ))}
      </Grid>
      )}

      {id && (
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12} sm={4}>
            <Button
              component={Link}
              variant="contained"
              to="/proyectos"
              color="secondary"
              startIcon={<NavigateBeforeIcon />}
            >
              Volver a Proyectos
            </Button>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

Proyectos.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

Proyectos.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default Proyectos;
