import React from 'react';
import {
  Grid, Container, Box, Link, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Facebook as FacebookIcon,
  LinkedIn as LinkedInIcon,
  Instagram as InstagramIcon,
} from '@material-ui/icons';

import i18 from '../i18n';

const SocialNetworkIcon = ({ children }) => {
  const items = [
    { component: <FacebookIcon color="secondary" />, name: 'facebook' },
    { component: <LinkedInIcon color="secondary" />, name: 'linkedin' },
    { component: <InstagramIcon color="secondary" />, name: 'instagram' },
  ];
  return items.find((item) => item.name === children).component || null;
};

const Footer = () => {
  const { components: { footer: texts } } = i18;
  const classes = useStyles();

  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className={classes.footer}>
        <Container>
          <Grid container direction="row" justify="space-between">
            <Grid item xs={12} sm={4} align="left">
              <iframe
                key="iframe"
                title="iframe-facebook"
                src={texts.facebookIframe}
                width="340"
                height="160"
                scrolling="no"
                frameBorder="0"
                allow="encrypted-media"
              />
            </Grid>
            <Grid item xs={12} sm={4} align="right">
              <Typography className={classes.text}>{texts.location}</Typography>
              {texts.email.includes('@')
                ? (
                  <Link href={`mailto:${texts.email}`} color="inherit" key={texts.email}>
                    <Typography className={classes.text}>{texts.email}</Typography>
                  </Link>
                ) : <Typography className={classes.text}>{texts.email}</Typography>}
              <Box mt={1}>
                {texts.rrss.map((item) => (
                  <Link
                    key={item.name}
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer "
                  >
                    <SocialNetworkIcon>{item.name}</SocialNetworkIcon>
                  </Link>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.downFooter}>
        <Grid container direction="row" justify="space-between">
          <Grid item sm={12} align="center">
            <Typography>
              {`${texts.title.toUpperCase()} | Todos los derechos reservados © ${currentYear}`}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const useStyles = makeStyles({
  footer: {
    backgroundColor: '#f2f2f2',
    textTransform: 'uppercase',
    marginTop: '2em',
    paddingTop: '2em',
  },
  downFooter: {
    backgroundColor: '#822F56',
    color: 'white',
    paddingLeft: '1em',
    paddingRight: '1em',
  },
  text: {
    fontSize: '.875em',
    marginBottom: '.5em',
    marginTop: '.5em',
  },
});

export default Footer;
