import React from 'react';
import PropTypes from 'prop-types';

const YoutubeVisualizer = ({ youtubeId }) => (
  <iframe
    title="Youtube Video"
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    }}
    src={`https://www.youtube.com/embed/${youtubeId}`}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />
);

YoutubeVisualizer.propTypes = {
  youtubeId: PropTypes.string,
};

YoutubeVisualizer.defaultProps = {
  youtubeId: '',
};

export default YoutubeVisualizer;
