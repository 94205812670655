import React, { useEffect, useState, useContext } from 'react';
import {
  Grid, Container, Button, CircularProgress,
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import i18n from '../../i18n';
import { Division, DownloadButton } from '../../components';
import WebContext from '../../contexts/webContext';

const Balances = ({ match: { params: { id } } }) => {
  const { pages: { balances: texts } } = i18n;

  const [principal, setPrincipal] = useState({});
  const { balances } = useContext(WebContext);

  useEffect(() => {
    setPrincipal(balances.data.find((item) => item.id.toString() === id));
  }, [id, balances]);

  return (
    <Container>
      <Helmet>
        <meta name="description" content={texts.title} />
        <meta property="og:description" content={texts.title} />
      </Helmet>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Division title={texts.title} description={texts.content} isMain />
        </Grid>
      </Grid>

      {balances.initializing && (
      <Grid container spacing={3} alignItems="center" direction="column">
        <Grid item xs={12}>
          <CircularProgress color="secondary" />
        </Grid>
      </Grid>
      )}

      {principal && (
      <Grid container spacing={3} direction="column" alignItems="center">
        <Grid item xs={10} sm={8}>
          <Division title={principal.title} description={principal.content} descriptionAlign="justify" />
        </Grid>

        {principal.files && principal.files.map((file) => (
          <Grid item xs={12} sm={4} key={file.id}>
            <DownloadButton url={file.url} name={file.name} />
          </Grid>
        ))}
      </Grid>
      )}

      {balances.data.length > 0 && !principal && (
      <Grid container spacing={3}>
        {balances.data.map((item) => (
          <Grid item xs={12} sm={4} key={item.id}>
            <Division
              title={item.title}
              description={item.content}
              descriptionAlign="justify"
              maxChararters={180}
              path={`/balances/${item.id}`}
            />
          </Grid>
        ))}
      </Grid>
      )}

      {id && (
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12} sm={4}>
            <Button
              component={Link}
              variant="contained"
              to="/balances"
              color="secondary"
              startIcon={<NavigateBeforeIcon />}
            >
              Volver a Balances
            </Button>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

Balances.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

Balances.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default Balances;
