export default {
  name: 'Puerto Mestizo',
  description: '',
  type: 'website',
  url: 'https://puertomestizo.cl',
  components: {
    header: {
      title: 'Puerto Mestizo',
    },
    footer: {
      title: 'Puerto Mestizo',
      location: 'Valparaíso, Chile',
      email: 'fundacion@puertomestizo.cl',
      phone: '',
      facebookIframe:
        'https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FFundación-Puerto-Mestizo-2359099804413362%2F&tabs=timeline&width=340&height=70&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true',
      rrss: [
        {
          name: 'facebook',
          link:
            'https://www.facebook.com/Fundación-Puerto-Mestizo-2359099804413362',
        },
        {
          name: 'instagram',
          link: 'https://www.instagram.com/fundacion.puerto.mestizo/',
        },
      ],
    },
  },
  pages: {
    inicio: {
      title: 'Puerto Mestizo',
      sections: [
        {
          id: 'mision',
          title: 'Misión',
          description:
            'Somos una organización que aporta al desarrollo e integración social de diversas comunidades latinoamericanas en situación de vulnerabilidad socio-cultural. Nos diferenciamos por crear instancias de intercambio cultural a través de la educación artística.',// eslint-disable-line
        },
        {
          id: 'vision',
          title: 'Visión',
          description:
            'Consolidarnos como un eje de desarrollo e integración socio-cultural en Latinoamérica.',
        },
      ],
    },
    proyectos: {
      title: 'Proyectos',
      subtitle: 'Otros proyectos',
      content: 'Proyectos realizados por la fundación',
    },
    balances: {
      title: 'Balances',
    },
  },
};
