import React, { useContext } from 'react';
import {
  Grid, Container, Box, CircularProgress,
} from '@material-ui/core';
import { Helmet } from 'react-helmet';

import i18n from '../../i18n';
import { FullImage, Division } from '../../components';
import images from '../../assets/images';
import WebContext from '../../contexts/webContext';

const Inicio = () => {
  const { pages: { inicio: texts } } = i18n;
  const { inicios } = useContext(WebContext);

  return (
    <>
      <Helmet>
        <meta name="description" content={texts.description} />
        <meta property="og:description" content={texts.description} />
      </Helmet>

      <FullImage title={texts.title} image={images.backgroundInicio} />

      <Container>
        <Box mt={3}>
          <Grid container spacing={3} alignItems="center" direction="column">
            {inicios.initializing
              ? <CircularProgress color="secondary" />
              : inicios.data.map((item) => (
                <Grid item xs={10} sm={8} key={item.id}>
                  <Division title={item.title} description={item.content} descriptionAlign="justify" />
                </Grid>
              ))}
          </Grid>
        </Box>
      </Container>
    </>
  );
};
export default Inicio;
