import API from '../infraestructure/api';

export async function getProjects(params) {
  try {
    const response = await API.get('/projects', { params });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function getInicios(params) {
  try {
    const response = await API.get('/inicios', { params });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function getBalances(params) {
  try {
    const response = await API.get('/balances', { params });
    return response.data;
  } catch (error) {
    return [];
  }
}
