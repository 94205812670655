import React, { Fragment } from 'react';
import {
  Typography,
  Button,
  Icon,
  Box,
  Avatar,
  Card,
  CardActionArea,
  CardMedia,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const Division = ({
  icon,
  title,
  avatar,
  description,
  descriptionAlign,
  subtitle,
  path,
  items,
  isMain,
  children,
  textButton,
  image,
  instagramId,
  maxChararters,
}) => {
  const classes = useStyles();
  return (
    <>
      {(icon || avatar || title || subtitle || image) && (
        <Box align="center" className={classes.mainBox}>
          {icon && <Icon fontSize="large">{icon}</Icon>}
          {avatar && <Avatar alt={title} src={avatar} sizes="larger" />}
          {title && (
            title.split('\\n').map((item) => (
              <Typography
                variant={isMain ? 'h4' : 'h5'}
                style={{ whiteSpace: 'pre-line' }}
                key={item}
              >
                {item}
              </Typography>
            )))}
          {image && (
            <Link to={path}>
              <Card className={classes.mt}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt={title}
                    height="140"
                    image={image}
                    title={title}
                  />
                </CardActionArea>
              </Card>
            </Link>
          )}
          {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
        </Box>
      )}
      {(description || instagramId || items.length > 0) && (
        <Box className={classes.contents}>
          {description && (
            <Typography variant={isMain ? 'h5' : undefined} align={descriptionAlign}>
              {maxChararters !== 0 && description.length > maxChararters
                ? `${description.substring(0, maxChararters)}...`
                : description}
            </Typography>
          )}

          {instagramId && (
            <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                  component="iframe"
                  title="Video"
                  height={isMain ? '800' : '400'}
                  src={`https://www.instagram.com/p/${instagramId}/embed`}
                />
              </CardActionArea>
            </Card>
          )}

          {items.length > 0
            && items.map((item) => (
              <Typography key={item.key} align="justify">
                <Icon fontSize="small" className={classes.iconList}>
                  arrow_right
                </Icon>
                {`${item.description}`}
              </Typography>
            ))}
        </Box>
      )}
      {path && (
        <Box align="center" mt={1}>
          <Button
            component={Link}
            variant="contained"
            to={path}
            color="secondary"
          >
            {textButton}
          </Button>
        </Box>
      )}
      {children}
    </>
  );
};

const useStyles = makeStyles({
  contents: {
    color: '#858588',
  },
  iconList: {
    verticalAlign: 'middle',
  },
  mt: {
    marginTop: '.5em',
  },
  mb: {
    marginBottom: '.5em',
  },
  mainBox: {
    marginTop: '1em',
    marginBottom: '1em',
  },
});

Division.defaultProps = {
  title: '',
  avatar: '',
  icon: '',
  description: '',
  descriptionAlign: 'center',
  subtitle: [],
  path: '',
  items: [],
  isMain: false,
  children: false,
  textButton: 'Ver más',
  image: '',
  instagramId: '',
  maxChararters: 0,
};

Division.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  avatar: PropTypes.string,
  description: PropTypes.string,
  descriptionAlign: PropTypes.string,
  subtitle: PropTypes.node,
  path: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, value: PropTypes.string })),
  isMain: PropTypes.bool,
  children: PropTypes.node,
  textButton: PropTypes.string,
  image: PropTypes.string,
  instagramId: PropTypes.string,
  maxChararters: PropTypes.number,
};

export default Division;
