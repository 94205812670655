import React from 'react';
import { Button, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import GetAppIcon from '@material-ui/icons/GetApp';

const DownloadButton = ({ url, name }) => (
  <Link href={url} target="_blank" rel="noopener noreferrer " style={{ textDecoration: 'none' }}>
    <Button
      variant="contained"
      color="secondary"
      rel="noopener"
      startIcon={<GetAppIcon />}
    >
      {`${name}`}
    </Button>
  </Link>
);

DownloadButton.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
};

DownloadButton.defaultProps = {
  url: undefined,
  name: '',
};

export default DownloadButton;
