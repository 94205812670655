import { createMuiTheme } from '@material-ui/core/styles';
import images from './assets/images';

const Theme = createMuiTheme({
  // typography: {
  //   fontFamily: 'Open sans',
  // },
  palette: {
    primary: { main: '#F5F5F5' },
    secondary: { main: '#822F56' },
  },
  overrides: {
    MuiButton: {
      root: {
        textAlign: 'center',
        position: 'inherit',
        borderRadius: '1em',
      },
      label: {
        alignItems: 'flex-start',
      },
      raisedPrimary: {
        backgroundColor: '#822F56',
      },
    },
    MuiTypography: {
      root: {
        whiteSpace: 'pre-wrap',
      },
      h4: {
        fontWeight: 600,
        textTransform: 'uppercase',
        background: `url(${images.bottomLine}) no-repeat bottom center`,
        paddingTop: '0.5em',
        paddingBottom: '0.5em',
      },
      subtitle1: {
        fontWeight: 600,
        color: '#F5F5F5',
      },
    },
    MuiGrid: {
      container: {
        paddingTop: '1em',
        paddingBottom: '1em',
      },
    },
    MuiAvatar: {
      root: {
        width: '10em',
        height: '10em',
        margin: '1em',
      },
    },
  },
});

export default Theme;
