import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getProjects, getInicios, getBalances } from '../services/strapi';

const WebContext = React.createContext({});
export function WebContextProvider({ children }) {
  const [inicios, setInicios] = useState({ initializing: true, data: [] });
  const [balances, setBalances] = useState({ initializing: true, data: [] });
  const [projects, setProjects] = useState({ initializing: true, data: [] });

  const sortByOrder = (a, b) => {
    if (a.order > b.order) {
      return 1;
    }
    if (a.order < b.order) {
      return -1;
    }
    return 0;
  };

  useEffect(() => {
    const fill = async () => {
      setInicios({ initializing: false, data: await getInicios() });
      setBalances({ initializing: false, data: (await getBalances()).sort(sortByOrder) });
      setProjects({ initializing: false, data: (await getProjects()).sort(sortByOrder) });
    };
    fill();
  }, []);

  return (
    <WebContext.Provider value={{ balances, inicios, projects }}>
      {children}
    </WebContext.Provider>
  );
}

WebContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WebContext;
