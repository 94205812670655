import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';

import { Footer, Header } from './components';
import {
  Inicio, Contacto, NotFound, Proyectos, Gracias, Balances,
} from './containers';

const Routes = () => (
  <BrowserRouter>
    <ScrollToTop>
      <Header />
      <Switch>
        <Route exact path="/" component={Inicio} />
        <Route path="/proyectos/:id?" component={Proyectos} />
        <Route path="/balances/:id?" component={Balances} />
        <Route exact path="/contacto" component={Contacto} />
        <Route exact path="/contacto/gracias" component={Gracias} />
        <Route component={NotFound} />
      </Switch>
      <Footer />
    </ScrollToTop>
  </BrowserRouter>
);

export default Routes;
