import React from 'react';
import { Division } from '../../components';

const NotFound = () => (
  <div>
    <Division title="Página no encontrada" />
  </div>
);

export default NotFound;
